
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();
    const i18n = useI18n();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      MenuComponent.reinitialization();
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      t: i18n.t,
    };
  },
});
